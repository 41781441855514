var generic = generic || {};
var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

var MantleWindow = MantleWindow || {};
var MantleFEImages = MantleFEImages || {};

(function ($) {
  Drupal.behaviors.quickshopOverlay = {
    /*
     * Standard Drupal method
     */
    attach: function (context) {
      // util func to get the rendered quickshop output
      var _render_quickshop = function (template, productData, options) {
        if (!_.isEmpty(options) && !_.isEmpty(options.variables)) {
          productData = _.extend(productData, options.variables);
        }

        // We should always have a defaultSku but eh. Why not test.
        if (productData.defaultSku) {
          var quickshopImagePack = prodcat.ui.generateLargeImagePackWithWidth(
            productData.defaultSku,
            300
          );

          if (quickshopImagePack) {
            // Note that top level productData is always a copy when grabbed from
            // product_data. So this won't persist across getProduct calls.
            productData.quickshop_image_pack = quickshopImagePack;
          }
        }

        var rendered = site.template.get({
          name: template,
          data: productData
        });

        return rendered;
      };

      /*
       * 4 custom events below:
       * product.quickshop.launch.inline
       * product.quickshop.launch.overlay
       * product.quickshop.close.inline
       * product.quickshop.close.overlay
       */

      /*
       * This version replicates the inline quickshop except it will use an
       * absolutely positioned div. This is so we can add a background overlay.
       */

      var $products = $('.js-product', context);
      // eslint-disable-next-line complexity
      $products.on('product.quickshop.launch.inline', function (e, initial_sku_id, options) {
        // Store relevant product values.
        var $product = $(this);
        var productData = null;

        // default to hash
        options = options || {};

        // Allow passing in productData explicitly.
        if (options.productData) {
          productData = options.productData;
        }

        var productDataOptions = {
          use_context_skus: true
        };

        if (options.productDataOptions) {
          productDataOptions = options.productDataOptions;
        }

        if (!productData) {
          productData = prodcat.data.getProductFromEl($product, productDataOptions);
        }

        // Store other elements for inserting product info into quickshop container.
        var $grid = $product.closest('.js-product-grid');
        var $allProducts = $('.js-product-brief', $grid);
        // Initialize some other variables
        var alreadyActive = $product.hasClass('active');
        var template = 'product_quickshop';

        // Close the quickshop inline if it is already active (and thefore already open).
        if (alreadyActive) {
          $product.trigger('product.quickshop.close.inline');

          return;
        }

        $product.trigger('product.quickshop.open_requested', ['inline']);

        var rendered = _render_quickshop(template, productData, options);

        // Make some items disappear before the big reveal.
        $('#js-quickshop-overlay-locked').remove();

        var $overlay_container = $('<div id="js-quickshop-overlay-locked">' + rendered + '</div>');
        var $overlay_background = $('<div id="quickshop-overlay-locked-background"></div>');

        $('body').append($overlay_background);
        $('body').append($overlay_container);

        var $quickshop = $('.js-quickshop', $overlay_container);

        $quickshop.data('triggering-product-element', $product);

        Drupal.attachBehaviors($quickshop);

        // this is used for the defaultSku. Previously defaultSku logic was in the
        // click handler. but that abstraction for that is not great since it
        // requires us to know of stuff outside of $product.
        if (!initial_sku_id) {
          initial_sku_id = productData.defaultSku.SKU_BASE_ID;
        }

        if (initial_sku_id) {
          $quickshop.trigger('product.skuSelect', [initial_sku_id]);
        }

        var _lock_to_product = function () {
          var container_coords = $product[0].getBoundingClientRect();
          var y_offset = window.scrollY || window.pageYOffset;
          var x_offset = window.scrollX || window.pageXOffset;
          var window_width = window.innerWidth;
          var window_height = window.innerHeight;
          var new_y = container_coords.top + y_offset;
          var new_x = container_coords.left + x_offset;
          var width = $product.outerWidth(false);

          // set the x in the middle of product
          new_x = new_x + width / 2;
          var quickshop_width = $quickshop.outerWidth(false);
          var quickshop_height = $quickshop.outerHeight(false);
          // calculate if right edge of quickshop would be out of window
          var over_right_edge = new_x + quickshop_width / 2 - window_width;

          if (over_right_edge > 0) {
            new_x = new_x - over_right_edge;
          }
          var over_left_edge = new_x - quickshop_width / 2;

          if (over_left_edge < 0) {
            // note overleft edge is negative
            new_x = new_x - over_left_edge;
          }
          var over_bottom_edge = new_y + quickshop_height;

          if (over_bottom_edge > window_height + y_offset) {
            new_y = window_height + y_offset - quickshop_height - 10;
          }
          $quickshop.css({
            top: new_y + 'px',
            left: new_x + 'px'
          });
        };

        MantleWindow.removeEvents('quickshop-lock');

        MantleWindow.onResize(_lock_to_product, 'quickshop-lock', 100);
        MantleWindow.onScroll(_lock_to_product, 'quickshop-lock', 100);
        _lock_to_product();

        // Set up an event trigger for the inline close button.
        $(document).on('click', '.js-quickshop-close-inline', function (e) {
          e.preventDefault();
          $product.trigger('product.quickshop.close.inline');
        });

        // clicking background closes quickshop
        $overlay_background.on('click', function () {
          e.preventDefault();
          $product.trigger('product.quickshop.close.inline');
        });

        $allProducts.removeClass('active');

        $product.trigger('product.quickshop.opened', ['inline']);
      });

      // Uses the supplied .js-product to load the proper product data.
      // Then fires up an overlay with the product_quickshop.mustache template.
      $('.js-product, .js-quickshop-trigger', context).on(
        'product.quickshop.launch.overlay',
        function (e, initial_sku_id, options) {
          var $product = $(this);

          // prevent nested js-product running twice
          if (!$product.is(e.target)) {
            return;
          }
          $product.trigger('product.quickshop.open_requested', ['overlay']);
          // Store relevant product values
          var productID = $product.data('product-id');
          var productData = prodcat.data.getProductFromEl($product);
          var template = 'product_quickshop';
          // Render the quickshop template
          var rendered = _render_quickshop(template, productData, options);

          generic.overlay.launch({
            content: rendered,
            height: 'auto',
            width: 1024,
            maxWidth: '90%',
            cssClass: 'qs-overlay-cbox',
            onComplete: function () {
              var $cbox = $('#colorbox.qs-overlay-cbox');
              var resizeFn = function () {
                $.colorbox.resize();
              };

              // Resize initially to get rid of the full background
              $.colorbox.resize();
              // Resize delayed by 200 to account for cached images
              resizeFn();
              // Resize on resize and image load
              $(window).on('resize.quickshop', resizeFn);

              MantleWindow.onResize(resizeFn, 'quickshop', 400);

              $cbox.find('img').on('load', resizeFn);
              $('.selectBox').selectBox();
              // Trigger event and pass current overlay
              $(document).trigger('quickshop.loaded', $cbox);
              $product.trigger('product.quickshop.opened', ['overlay']);
              $(document).on('addToCart.success', function () {
                $.colorbox.close();
              });
              $products.on('product.skuSelect product.shadeSelect', function (event) {
                $.colorbox.resize();
              });
              // select defaultSku
              var prodData = prodcat.data.getProduct($product.data('product-id'));
              var skuBaseId = prodData.defaultSku.SKU_BASE_ID;

              $('.js-quickshop', $cbox).trigger('product.skuSelect', [skuBaseId]);
            },
            onCleanup: function () {
              $product.trigger('product.quickshop.close.overlay');
            }
          });
        }
      );

      $products.on('product.quickshop.close.overlay', function () {
        var $product = $(this);

        $product.trigger('product.quickshop.closed', ['overlay']);
      });

      // Closes the inline quickshop template according to the .js-product supplied.
      $products.on('product.quickshop.close.inline', function () {
        var $product = $(this);
        var productID = $product.data('product-id');
        //     console.log("PRODUCT ID:");
        //     console.log(productID);
        var $grid = $product.closest('.js-product-grid');
        var $container = $('.js-quickshop-container--' + productID, $grid);
        // handle the overlay version
        var $overlay_container = $('#js-quickshop-overlay-locked');
        var $overlay_background = $('#quickshop-overlay-locked-background');

        $overlay_container.remove();
        $overlay_background.remove();
        $(window).off('.quickshop-lock');

        $product.removeClass('active');

        $container.hide().removeClass('active');

        $product.trigger('product.quickshop.closed', ['inline']);
      });

      // In response to clicking the inline launch link, find the closest product
      // and then trigger the appropriate event for the code above.
      $('.js-quickshop-launch-overlay-locked', context).on('click', function (e) {
        e.preventDefault();
        var $product = $(this).closest('.js-product');
        // select defaultSku
        var prodData = prodcat.data.getProduct($product.data('product-id'));
        var skuBaseId = prodData.defaultSku.SKU_BASE_ID;

        $product.trigger('product.quickshop.launch.overlay-locked', [skuBaseId]);

        // Trigger 'product.init' to set product status
        $('.js-product', '.js-quickshop').trigger('product:init');
      });

      // In response to clicking the inline launch link, find the closest product
      // and then trigger the appropriate event for the code above.
      $('.js-quickshop-launch-inline', context).on('click', function (e) {
        e.preventDefault();
        var $product = $(this).closest('.js-product');
        // select defaultSku
        var skuBaseId = null;

        if ($product.data('sku-base-id')) {
          skuBaseId = $product.data('sku-base-id');
        }

        $product.trigger('product.quickshop.launch.inline', [skuBaseId]);

        // Trigger 'product.init' to set product status
        $('.js-product', '.js-quickshop').trigger('product:init');
      });

      // Same as above, except for the overlay launch link.
      $('.js-quickshop-launch-overlay', context).on('click', function (e) {
        e.preventDefault();
        $(this).closest('.js-product').trigger('product.quickshop.launch.overlay');

        // Trigger 'product.init' to set product status
        $('.js-product', '.js-quickshop').trigger('product:init');
      });

      /*
       * Run inline on desktop and overlay on mobile
       */
      $('.js-quickshop-launch-inline-desktop', context).on('click', function (e) {
        e.preventDefault();
        var $product = $(this).closest('.js-product');
        var breakpoint = Unison.fetch.now();
        var isMobile = breakpoint.name === 'small';

        if (isMobile) {
          $product.trigger('product.quickshop.launch.overlay');

          // Trigger 'product.init' to set product status
          $('.js-product', '.js-quickshop').trigger('product:init');

          return;
        }

        // select defaultSku
        var skuBaseId = null;

        if ($product.data('sku-base-id')) {
          skuBaseId = $product.data('sku-base-id');
        }

        $product.trigger('product.quickshop.launch.inline', [skuBaseId]);

        // Trigger 'product.init' to set product status
        $('.js-product', '.js-quickshop').trigger('product:init');
      });

      $('.js-product-grid', context)
        .on('product.quickshop.reset', function () {
          Drupal.behaviors.quickshopOverlay.reset($(this));
        })
        .on('product.quickshop.init', function () {
          Drupal.behaviors.quickshopOverlay.initializeGrid($(this));
        });
    }
  };

  $(document).on('product.quickshop.opened', function (event, mode) {
    if (mode === 'inline') {
      $('body').addClass('quickshop-inline-opened');
    }
  });

  $(document).on('product.quickshop.closed', function (event, mode) {
    if (mode === 'inline') {
      $('body').removeClass('quickshop-inline-opened');
    }
  });
})(jQuery);
